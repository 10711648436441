import React from 'react'

const GoogleMap = () => {
    return (
        <div className="google-map-area section text-center section-padding-bottom">
            <div className="container">
                <div className="contact-map-area" data-aos="fade-up">
                    <iframe className="contact-map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3958.154375084705!2d80.59753477571554!3d7.223226314635269!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ae36f0f890c708f%3A0x8d628bbcbb500a86!2s558%2C%201%20Gelioya%20-%20Kurunduwaththa%20Rd%2C%20Gelioya!5e0!3m2!1sen!2slk!4v1690179737471!5m2!1sen!2slk"  aria-hidden="false"></iframe>
                </div>
            </div>
        </div>
    )
}

export default GoogleMap;
