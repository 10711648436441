import React from "react";
import SEO from "../components/SEO";
import Header from "../partials/header/Header";
import Breadcrumb from "../container/Breadcrumb/Breadcrumb";
import PageBlog from "../container/BlogGrid/PageBlog";
import CallToAction from "../container/CallToAction/CallToAction";
import Footer from "../container/Footer/Footer";
import ScrollToTop from "../components/ScrollToTop.jsx";

const Research = () => {
  return (
    <React.Fragment>
      <SEO title="CiviLanka || Research" />
      <Header />
      <Breadcrumb
        image="images/bg/research.jpg"
        title="Our research looks at the industry overall and projects what we expect future growth to be in an unbiased way."
        content="Home"
        contentTwo="Research"
      />
      <PageBlog />
      <CallToAction />
      <Footer />
      <ScrollToTop />
    </React.Fragment>
  );
};

export default Research;
