import React from "react";
import { Link } from "react-router-dom";
import Logo from "../../components/logo/Logo";
import FooterData from "../../data/Footer/footerItem.json";
import FooterLinkItem from "../../components/Footer/FooterLinkItem.jsx";

const Footer = () => {
  return (
    <div className="footer-section section footer-bg-color">
      <div className="container">
        <div className="row mb-lg-14 mb-md-10 mb-6 d-flex justify-content-between">
          <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12 col-12 mb-6">
            <div className="footer-widget">
              <div className="footer-logo">
                <Logo
                  image={`${process.env.PUBLIC_URL}/images/logo/logo2.jpg`}
                />
              </div>
              <div className="footer-widget-content">
                <div className="content">
                  <p>
                    <Link to={process.env.PUBLIC_URL + "/"}>
                      (+94) 76 579 7472
                    </Link>
                  </p>
                  <p>
                    <Link to={process.env.PUBLIC_URL + "/"}>
                      contact@civilanka.com
                    </Link>{" "}
                  </p>
                </div>
                <div className="footer-social-inline">
                  <a
                    href="https://www.freelancer.com/u/ifamsiddeeq"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={`${process.env.PUBLIC_URL}/images/logo/free1.png`}
                    />
                  </a>
                  <a
                    href="https://www.upwork.com/freelancers/~0165d1e6cb32d955f4?viewMode=1"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={`${process.env.PUBLIC_URL}/images/logo/upwork.png`}
                    />
                  </a>
                  <a
                    href="https://www.linkedin.com/company/civilanka"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fab fa-linkedin"></i>
                    {/* <img src={`${process.env.PUBLIC_URL}/images/logo/free.png`} /> */}
                  </a>
                  {/* <a
                    href="https://www.freelancer.com/u/ifamsiddeeq"
                    target="_blank"
                    rel="noopener noreferrer"
                  > */}
                  {/* <img src={`${process.env.PUBLIC_URL}/images/logo/free.png`} height={24.09} width={21} /> */}
                  {/* <i className="fas fa-users"></i> */}
                  {/* </a> */}
                  <a
                    href="https://instagram.com/civilanka?igshid=MmIzYWVlNDQ5Yg=="
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fab fa-instagram"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex col justify-content-end">
            {FooterData &&
              FooterData.map((single, key) => {
                return (
                  <div
                    key={key}
                    className={`${key === 0 ? "mr-16 footer-about": "footer-about"} col-xl-2 col-lg-2 col-md-4 col-sm-6 col-6 mb-6`}
                  >
                    <FooterLinkItem data={single} key={key} />
                  </div>
                );
              })}
          </div>
        </div>

        <div className="row">
          <div className="col">
            <p className="copyright">
              &copy; 2023 <strong>CiviLanka</strong>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
