import React, { Fragment, useRef } from "react";
import { useForm } from "react-hook-form";
import { useAlert } from "react-alert";
import emailjs from "@emailjs/browser";

const ProjectForm = () => {
  const form = useRef();
  const alert = useAlert();

  const { register, handleSubmit, errors } = useForm({
    mode: "onBlur",
  });

  const sendEmail = () => {
    emailjs
      .sendForm(
        "service_rnm5gek",
        "template_xrih22n",
        form.current,
        "kXtXNqML6FTsX5AN3"
      )
      .then(
        (result) => {
          alert.success("Email submitted successfully!");
          form.current.reset();
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  return (
    <Fragment>
      <form ref={form} onSubmit={handleSubmit(sendEmail)}>
        <div className="row mb-n4">
          <div className="col-md-12 col-12 mb-4">
            <input
              type="text"
              placeholder="Your Name *"
              name="from_name"
              ref={register({ required: "Name is required" })}
            />
            {errors.from_name && <p>{errors.from_name.message}</p>}
          </div>
          <div className="col-md-12 col-12 mb-4">
            <input
              type="text"
              placeholder="Subject *"
              name="subject"
              ref={register({ required: "Subject is required" })}
            />
            {errors.subject && <p>{errors.subject.message}</p>}
          </div>
          <div className="col-md-12 col-12 mb-4">
            <input
              type="email"
              placeholder="Email *"
              name="email"
              ref={register({
                required: "Email is required",
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                  message: "invalid email address",
                },
              })}
            />
            {errors.email && <p>{errors.email.message}</p>}
          </div>
          <div className="col-12 mb-6">
            <textarea
              name="message"
              placeholder="Message"
              ref={register({ required: "Message is required" })}
            ></textarea>
            {errors.message && <p>{errors.message.message}</p>}
          </div>
          <div className="col-12 text-center mb-4">
            <button className="btn btn-primary btn-hover-secondary">
              Get a free consultation
            </button>
          </div>
        </div>
      </form>
      <p className="form-messege"></p>
    </Fragment>
  );
};

export default ProjectForm;
