import React from "react";
import { NavLink } from "react-router-dom";
// import { getClosest, getSiblings, slideToggle, slideUp } from "../../../utils";

const MobileNavMenu = () => {
  //   const onClickHandler = (e) => {
  //     const target = e.currentTarget;
  //     const parentEl = target.parentElement;
  //     if (
  //       parentEl?.classList.contains("menu-toggle") ||
  //       target.classList.contains("menu-toggle")
  //     ) {
  //       const element = target.classList.contains("icon") ? parentEl : target;
  //       const parent = getClosest(element, "li");
  //       const childNodes = parent.childNodes;
  //       const parentSiblings = getSiblings(parent);
  //       parentSiblings.forEach((sibling) => {
  //         const sibChildNodes = sibling.childNodes;
  //         sibChildNodes.forEach((child) => {
  //           if (child.nodeName === "UL") {
  //             slideUp(child, 1000);
  //           }
  //         });
  //       });
  //       childNodes.forEach((child) => {
  //         if (child.nodeName === "UL") {
  //           slideToggle(child, 1000);
  //         }
  //       });
  //     }
  //   };
  return (
    <nav className="site-mobile-menu">
      <ul>
        <li className="has-children">
          <NavLink to={process.env.PUBLIC_URL + "/"}>
            <span className="menu-text">HOME</span>
          </NavLink>
          {/* <span className="menu-toggle" onClick={onClickHandler}><i className="icon far fa-angle-down"></i></span>
                    <ul className="sub-menu">
                        <li><NavLink to={process.env.PUBLIC_URL + "/home"}><span className="menu-text">Home One</span></NavLink></li>
                        <li><NavLink to={process.env.PUBLIC_URL + "/home-two"}><span className="menu-text">Home Two</span></NavLink></li>
                        <li><NavLink to={process.env.PUBLIC_URL + "/home-three"}><span className="menu-text">Home Three</span></NavLink></li>
                    </ul> */}
        </li>
        <li>
          <NavLink to={process.env.PUBLIC_URL + "/about"}>
            <span className="menu-text">ABOUT US</span>
          </NavLink>
        </li>
        <li>
          <NavLink to={process.env.PUBLIC_URL + "/services"}>
            <span className="menu-text">SERVICES</span>
          </NavLink>
        </li>
        <li className="has-children">
          <NavLink to={process.env.PUBLIC_URL + "/projects"}>
            <span className="menu-text">PROJECTS</span>
          </NavLink>
          {/* <span className="menu-toggle" onClick={onClickHandler}><i className="far fa-angle-down"></i></span>
                    <ul className="sub-menu">
                        <li><NavLink to={process.env.PUBLIC_URL + "/work"}><span className="menu-text">Work</span></NavLink></li>
                        <li><NavLink to={process.env.PUBLIC_URL + `/work-details/1`}><span className="menu-text">Work Details</span></NavLink></li>
                    </ul> */}
        </li>
        <li className="has-children">
          <NavLink to={process.env.PUBLIC_URL + "/research"}>
            <span className="menu-text">RESEARCH</span>
          </NavLink>
          {/* <span className="menu-toggle" onClick={onClickHandler}><i className="far fa-angle-down"></i></span>
                    <ul className="sub-menu">
                        <li><NavLink to={process.env.PUBLIC_URL + "/blog-grid"}><span className="menu-text">Blog Grid</span></NavLink></li>
                        <li><NavLink to={process.env.PUBLIC_URL + "/blog-classic"}><span className="menu-text">Blog Classic</span></NavLink></li>
                        <li><NavLink to={process.env.PUBLIC_URL + `/blog-details/1`}><span className="menu-text">Blog Details</span></NavLink></li>
                    </ul> */}
        </li>
        <li>
          <NavLink to={process.env.PUBLIC_URL + "/contact"}>
            <span className="menu-text">CONTACT US</span>
          </NavLink>
        </li>
      </ul>
    </nav>
  );
};

export default MobileNavMenu;
