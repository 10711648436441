import React from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";

const Btn = (props) => {
  const history = useHistory();
  const handleClick = () => {
    history.push("/contact#contact-section");

    setTimeout(() => {
      const section = document.getElementById("contact-section"); // Replace 'section-id' with the actual section's ID
      if (section) {
        section.scrollIntoView({ behavior: "smooth" });
      }
    }, 100);
  };
  return (
    <React.Fragment>
      <button onClick={handleClick} className="btn btn-light btn-hover-primary">
        {props.name}
      </button>
    </React.Fragment>
  );
};

Btn.propTypes = {
  name: PropTypes.string,
};

export default Btn;
