import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";

const WorkItemTwo = ({ data }) => {
  return (
    <div className="work">
      <div className="thumbnail h-50">
        <Link
          className="image h-100"
          to={process.env.PUBLIC_URL + `/work-details/${data.id}`}
        >
          <img src={process.env.PUBLIC_URL + data.largeImage} alt="work" className="h-100" />
        </Link>
      </div>
      <div className="info h-50">
        <h3 className="title">
          <Link to={process.env.PUBLIC_URL + `/work-details/${data.id}`}>
            {data.title}
          </Link>
        </h3>
        {/* <p className="desc">{data.excerpt}</p> */}
        <Link
          to={process.env.PUBLIC_URL + `/work-details/${data.id}`}
          className="position-absolute bottom-0 pb-4"
        >
          View Project
        </Link>
      </div>
    </div>
  );
};

WorkItemTwo.propTypes = {
  data: PropTypes.object,
};

export default WorkItemTwo;
